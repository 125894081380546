import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Burger from '../HamburgerMenu';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'gatsby';
import DropdownMenu from '../DropdownMenu';
import GlassHiveLogoDark from '../GlassHiveLogoDark';
import GlassHiveLogoLight from '../GlassHiveLogoLight';
import { Helmet } from 'react-helmet';
import ShinyButton from '../ShinyButton';
import { throwSignupConversionEvent } from '../../scripts/utils';

import './style.scss';

const honeyIntegration =
    typeof window !== 'undefined'
        ? require('../../scripts/honey-form-integration.js')
        : null; // TODO: Does this need to update?
const honeyValidation =
    typeof window !== 'undefined'
        ? require('../../scripts/honey-form-validation.js')
        : null; // TODO: Does this need to update?

const Header = ({ navTheme, siteTitle, headerVersion }) => {
    const [transparent, setTransparent] = useState(true);
    const [scrollTop, setScrollTop] = useState(0);
    const [hideNav, setHideNav] = useState(false);

    const getScrollTop = () => {
        return window.pageYOffset || document.documentElement.scrollTop;
    };

    const handleScroll = () => {
        const currentScrollTop = getScrollTop();

        if (window.scrollY === 0) {
            setTransparent(true);
            setHideNav(false);
        } else {
            setTransparent(false);
            setHideNav(currentScrollTop > scrollTop);
        }

        setScrollTop(currentScrollTop);
    };

    // Check scroll position on initial render
    useEffect(() => {
        handleScroll();
    }, []);

    // Check scroll position every render
    useEffect(() => {
        setScrollTop(getScrollTop());
        window.addEventListener('scroll', handleScroll);

        honeyIntegration.default.setup();
        honeyValidation.default.run();

        // Cleanup listener on re-render
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <>
            <Helmet>
                <link
                    href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800"
                    rel="stylesheet"
                ></link>
                <link
                    href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700"
                    rel="stylesheet"
                ></link>
            </Helmet>
            <header
                className={`fixed ${transparent ? '' : 'end-transparency'} ${
                    hideNav ? 'hide-nav' : 'show-nav'
                }`}
            >
                <CookieConsent
                    cookieName="CookieConsent"
                    disableStyles={true}
                    location={"bottom"}
                    containerClasses="cookie-consent-container"
                    contentClasses="cookie-consent-content"
                    buttonClasses="cookie-consent-accept-button"
                    declineButtonClasses="cookie-consent-decline-button"
                    expires={150}
                    buttonText="Accept all cookies"
                    declineButtonText="Necessary cookies only"
                    enableDeclineButton={true}
                    flipButtons={true}
                >
                    We use cookies to enhance your experience on our site.
                    Some cookies are necessary for our website and services to function properly.
                    Other cookies are optional and help personalize your experience, including advertising and analytics.
                    You can consent to all cookies, or decline all optional cookies.
                    If no selection is made, our default cookie settings will apply.
                    You can change your preferences at any time.
                    To learn more, check out our <a href="https://app.glasshive.com/Account/PrivacyPolicy" target="_blank">Privacy Policy</a>.
                </CookieConsent>
                <div
                    className={'mainHeader navbar ' + navTheme}
                    role="navigation"
                >
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item">
                            <img
                                src={
                                    navTheme === 'light'
                                        ? require('../../images/nav/white-gh-new-header-logo.png')
                                        : require('../../images/nav/gh-new-header-logo.png')
                                }
                                alt="glasshive logo"
                            />
                        </Link>
                        <Burger className="burger-container" />
                    </div>
                    <div id="navbarBasicExample" className="navbar-menu">
                        <div className="navbar-start">
                            <div className="dropdown-container">
                                <button className="navbar-item features-nav">
                                    Features
                                </button>
                                <DropdownMenu />
                            </div>
                            <Link to="/pricing" className="navbar-item">
                                Pricing
                            </Link>
                            <Link to="/resources" className="navbar-item">
                                Resources
                            </Link>
                            <Link to="/reviews" className="navbar-item">
                                Reviews
                            </Link>
                            <Link to="/careers" className="navbar-item">
                                Careers
                            </Link>
                        </div>
                        <div className="navbar-end">
                            <div className="navbar-item">
                                <div className="buttons">
                                    <ShinyButton
                                        className="btn blue first"
                                        href="https://app.glasshive.com"
                                    >
                                        Log in
                                    </ShinyButton>
                                    <ShinyButton
                                        className="btn pink margin-left-50 create-free-account-button"
                                        onClick={() =>
                                            throwSignupConversionEvent(
                                                'https://app.glasshive.com/Account/Login?s=signup'
                                            )
                                        }
                                    >
                                        Start Free Trial
                                    </ShinyButton>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
