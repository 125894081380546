import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Socket from '../Socket';
import NewsletterSignup from '../NewsletterSignup';
import './style.scss';

const Footer = () => {
    return (
        <footer className="fixed" id="footer">
            <Row>
                <Col className="footer-col-1">
                    <h6>Contact Us</h6>
                    <ul>
                        <li>
                            <a
                                href="https://www.google.com/maps/place/900+W+Bethany+Dr+%23240,+Allen,+TX+75013/@33.0912974,-96.6863007,17z/data=!3m1!4b1!4m5!3m4!1s0x864c1742b404d44b:0x1198abcb87983c9!8m2!3d33.0912974!4d-96.684112?shorturl=1"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                900 W Bethany Dr, Suite 240
                                <br />
                                Allen, TX 75013
                            </a>
                        </li>
                        <li>
                            <a
                                href="tel:4699652557"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Phone: (469) 965-2557
                            </a>
                        </li>
                        <li>
                            <a
                                href="mailto:info@glasshive.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Email:{' '}
                                <span className="link-highlight">
                                    info@glasshive.com
                                </span>
                            </a>
                        </li>
                    </ul>
                    <img
                        className="certGraphic"
                        src={require('../../images/nav/GlassHive_Horinzontal.svg')}
                        alt="google partners"
                    ></img>
                </Col>
                <Col className="footer-col-5">
                    <h6>Social Links</h6>
                    <ul>
                        <li>
                            <a
                                href="https://www.facebook.com/GlassHiveOS/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={require('../../svgs/nav/facebook.svg')}
                                    alt="facebook"
                                ></img>
                                Facebook
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/company/glasshive"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={require('../../svgs/nav/linkedin.svg')}
                                    alt="linkedIn"
                                ></img>
                                LinkedIn
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://twitter.com/GlassHiveOS"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={require('../../svgs/nav/twitter.svg')}
                                    alt="twitter"
                                ></img>
                                Twitter
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.youtube.com/channel/UCpoZo1579qRaQz32NtVW_BA"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={require('../../svgs/nav/youtube.svg')}
                                    alt="youtube"
                                ></img>
                                Youtube
                            </a>
                        </li>
                        {/* <li>
                            <a
                                href="https://www.instagram.com/honey_crm/?hl=en" // TODO: Will this get updated with the rebrand?
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={require('../../svgs/nav/IG.svg')}
                                    alt="instagram"
                                ></img>
                                Instagram
                            </a>
                        </li> */}
                    </ul>
                </Col>
                <Col>
                    <NewsletterSignup />
                </Col>
                <Col className="footer-col-6">
                    <h6>Accolades</h6>
                    <li>
                        <div>
                            <img
                                src={require('../../images/accolades/software-advice.png')}
                                alt="software advice badge"
                                className="software-advice"
                            ></img>
                        </div>
                        <div>
                            <img
                                className="capterra-img"
                                src={require('../../images/accolades/capterra.png')}
                                alt="capterra badge"
                                className="capterra"
                            ></img>
                        </div>
                    </li>
                    <li className="badge-row-2">
                        <div>
                            <img
                                src={require('../../images/accolades/best-value.png')}
                                alt="best value badge"
                                className="capterra-badge"
                            ></img>
                        </div>
                        <div>
                            <img
                                src={require('../../images/accolades/best-ease-of-use.png')}
                                alt="best ease of use badge"
                                className="capterra-badge"
                            ></img>
                        </div>
                        <div>
                            <img
                                src={require('../../images/accolades/get-app.png')}
                                alt="getapp badge"
                                className="getapp"
                            ></img>
                        </div>
                    </li>
                </Col>
            </Row>
            <Socket />
        </footer>
    );
};

export default Footer;
